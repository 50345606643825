require('bootstrap')
require('lightbox2')

import Glide from '@glidejs/glide'
import { create } from 'domain';


require('./app.scss');
require('../node_modules/lightbox2/src/css/lightbox.css');
require('../node_modules/@fortawesome/fontawesome-free/css/all.css');


$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

$.fn.evoCalendar = function(opt) {
    var defaults = {
                    api:       '',
                    calendar_url: '',
                    new_window: '',
                    _action: '',
                    loading_text :'Lade Kalender...'                                    
    };
    var options = $.extend({}, defaults, opt);
    
    if(options.api === undefined) return;

    var $el = this;

    $el.html( options.loading_text);

    $.getJSON( options.api, function( data ) {
                    //console.log(data);
                    
                    $el.html( '<style type="text/css">'+ data.styles +'</style>');
                    $el.append(data.html);
    });

    // click on events
  $el.on('click','a.desc_trig',function(){
                    var OBJ = $(this);
                    var url = (options.calendar_url == ''  )?
                    OBJ.closest('.eventon_list_event').find('.evo_event_schema').find('a').attr('href'):
                                    options.calendar_url;
                    
                    var open = (options.new_window === undefined || options.new_window == false)? '_self':'_blank';
                    //console.log(OBJ);
                    if(url!== undefined && url!='') window.open(url, open);
                    return false;
    });

};




$(window).on('resize scroll', function() {
    if ($('footer').isInViewport()) {
        if(!$(".registration").hasClass("scroll-footer")){
            $(".registration").addClass("scroll-footer");
            $("#trial img").attr("src", "/img/icon-trial.png");
            $("#register img").attr("src", "/img/icon-member.png");
         }
        
    } else {
        if($(".registration").hasClass("scroll-footer")){
            $(".registration").removeClass("scroll-footer");
            $("#trial img").attr("src", "/img/icon-trial-white.png");
            $("#register img").attr("src", "/img/icon-member-white.png");
         } 
    }
});


var glide1;
var glide2;

function createGlide() {

    var glide2 = document.querySelectorAll('.glide2');

    for (var i = 0; i < glide2.length; i++) {
      glide1 = new Glide(glide2[i], {
        autoplay: 3000,
        perView: 2,
        bound: true,
        breakpoints: {
          1000: {
            perView: 2
            },
          600: {
            perView: 1
          }
        }
      });

      glide1.mount()
    }

    var glide3 = document.querySelectorAll('.glide3');

    for (var i = 0; i < glide3.length; i++) {
      glide2 = new Glide(glide3[i], {
        autoplay: 3000,
        perView: 3,
        bound: true,
        breakpoints: {
          1000: {
            perView: 2
            },
          600: {
            perView: 1
          }
        }
      });

      glide2.mount()
    }

    var glides_front = document.querySelectorAll('.glide1');

    for (var i = 0; i < glides_front.length; i++) {
      var glide_front = new Glide(glides_front[i], {
        autoplay: 3000,
        perView: 1,
        bound: true,
      });

      glide_front.mount()
    }
} 


$(document).on('click', '.module-header button', function(e) {

    if(glide1) {
      glide1.destroy();
    }
    if(glide2) {
      glide2.destroy();
    }
    createGlide();
})

$(document).ready(function($){
  $('#eventoncontent').evoCalendar({
              api: 'https://marketinglive.events/wp-json/eventon/calendar?event_type_2=198&number_of_months=3&event_count=3&show_et_ft_img=no&hide_mult_occur=yes',
              new_window: true,
              loading_text: 'Lade...',
  });

  createGlide()

});








